import { getSelfScriptSrc } from './dom';
import { getHublet, isCmsQa } from './cms';
import { PlayerTypes } from '../types';

// cannot depend on enviro or other basic HeadJS utils here
export const ENV_KEY = 'VIDEO_PLAYER_ENV';
const QA_LIVEPATH_SUFFIX = '-qa.js';
const QA_APP_DOMAINS = ['.hubspotqa.com', '.hubspotvideoqa.com', '.hsappstatic.net'];
const STATIC_APP_ORIGIN_PATTERN = /http(s?):\/\/(app|local)(-\w+)?.hubspot(qa)?.com/;
let localStorageErrorThrown = false;
export function tryLocalStorageGet(key, retryAfterError = true) {
  if (!retryAfterError && localStorageErrorThrown) {
    return null;
  }
  try {
    return localStorage.getItem(key);
  } catch (e) {
    localStorageErrorThrown = true;
    return null;
  }
}
const getWindowEnv = () => window[ENV_KEY];
export function isLocal() {
  if (getWindowEnv()) {
    return getWindowEnv() === 'local';
  }
  if (tryLocalStorageGet(ENV_KEY) === 'local') {
    return true;
  }
  if (window.location.search.includes('_hsVideoEnv=local')) {
    return true;
  }
  return false;
}
export function isQa() {
  if (isCmsQa() || tryLocalStorageGet(ENV_KEY) === 'qa') {
    return true;
  }
  if (getWindowEnv()) {
    return getWindowEnv() === 'qa';
  }
  const embedScriptSrc = getSelfScriptSrc();
  return QA_APP_DOMAINS.some(domain => window.location.hostname.endsWith(domain)) || embedScriptSrc && embedScriptSrc.endsWith(QA_LIVEPATH_SUFFIX);
}
export function getEmbedIframeDomain(options = {}) {
  const region = options.region || getHublet();
  const regionSuffix = region ? `-${region}` : '';
  if (options && options.env === 'prod') {
    return `https://${isLocal() ? 'local' : 'play'}.hubspotvideo.com`;
  }
  if (isAuthedAppPreview() && options.playerType !== PlayerTypes.ACADEMY_IN_APP) {
    // for specific case of running locally on default QA hublet, default to app domain unless there are local VIDEO_PLAYER_ENV overrides
    if (isQa() && !isLocal() && !getHublet()) {
      return `https://app.hubspotqa.com`;
    }
    if (!options.region && !options.env) {
      // use parent window origin instead, as CSP rules prevent crossing local/app subdomains in all other cases
      return window.location.origin;
    }
  }
  if (isLocal()) {
    // bender-proxy cert doesn't work for `local-eu1.hubspotvideoqa.com`
    return isQa() ? 'https://local.hubspotvideoqa.com' : 'https://local.hubspotvideo.com';
  }
  const appDomain = isQa() || options.env === 'qa' ? 'hubspotvideoqa.com' : 'hubspotvideo.com';
  return `https://play${regionSuffix}.${appDomain}`;
}
export function isJasmine() {
  return 'jasmineRequire' in window;
}
export function isAuthedAppPreview(location) {
  if (isJasmine() && !location) {
    return false;
  }
  return Boolean((location || window.location).origin.match(STATIC_APP_ORIGIN_PATTERN));
}
export function isIframed() {
  return window.parent !== window;
}
export function isSelenium() {
  return Boolean(tryLocalStorageGet('selenium.disable.alarms'));
}
const userAgent = window.navigator ? navigator.userAgent.toLowerCase() : '';
export const isEdge = () => userAgent.includes('edge/');

// borrowed from https://git.hubteam.com/HubSpot/UIComponents/blob/master/UIComponents/static/js/utils/BrowserTest.ts
export const isSafari = () => userAgent.includes('safari/') && !userAgent.includes('chrome/') && !isEdge();
const IGNORED_USER_AGENT_PATTERNS = ['Screaming Frog SEO Spider/',
// caused spikes leading to rhumb failure alerts on 7/31/22
'startbase',
// caused errors-per-session alert on 9/28/22
'Unsafe_environment', 'site24x7'];
export function shouldIgnoreUserAgent() {
  return IGNORED_USER_AGENT_PATTERNS.some(token => navigator.userAgent && navigator.userAgent.includes(token));
}