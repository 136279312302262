import { CONFIRM_PLAYER_RENDERED_DELAY_MS, DEFAULT_ASPECT_RATIO, MessageTypes } from '../constants';
import { ConversionAssetPositions, ConversionAssetTypes, PlayerStatus, PlayerTypes } from '../types';
import { debugLog } from './debug';
import { getEmbedPlaceholderEl, isVisible, query, SERVER_RENDERED_IFRAME_SELECTOR } from './dom';
import { getEmbedIframeDomain, isAuthedAppPreview, isLocal } from './env';
import { reportMessage } from './errors';
import { renderCta, renderForm } from './overlay';
import { getPerformanceNow } from './performance';
import { isValidPlaceholder } from './placeholders';
import { buildIframeQueryParams, getEmbedIframeUrl, parseQueryParams, serializeConversionAsset, stringifyQueryParams } from './url';
export default class EmbedPlayer {
  constructor(id, portalId, _options, _placeholderEl) {
    this.status = PlayerStatus.INITIAL;
    this.confirmedVisible = false;
    this.initiallyVisible = false;
    this.preloaderIframeLoaded = false;
    this.iframeLoaded = false;
    this.getWrapperEl = () => {
      if (this.el.matches('.hs-video-wrapper')) {
        return this.el;
      }
      return this.el.querySelector('.hs-video-wrapper');
    };
    this.injectPlayer = () => {
      const {
        options,
        placeholderEl
      } = this;
      if (placeholderEl.querySelector(SERVER_RENDERED_IFRAME_SELECTOR)) {
        this.iframeEl = placeholderEl.querySelector(SERVER_RENDERED_IFRAME_SELECTOR);
      } else if (this.selectIframeElFromPage()) {
        reportMessage('injectPlayer found iframe el on page via selector, not inserting again.');
        this.replaceIframeFromPage();
        return;
      } else if (placeholderEl) {
        this.injectIframe(placeholderEl);
        placeholderEl.setAttribute('data-hsv-status', 'rendered');
      } else {
        reportMessage(`injectPlayer cannot find placeholder, no way to render!`);
        return;
      }
      if (!isAuthedAppPreview() && options.playerType !== PlayerTypes.IN_APP_PREVIEW && options.playerType !== PlayerTypes.EDITOR_PREVIEW && !options.autoplay) {
        setTimeout(() => this.confirmRendered(), CONFIRM_PLAYER_RENDERED_DELAY_MS);
        if (window.IntersectionObserver) {
          try {
            this.setupIntersectionObserver();
          } catch (e) {
            this.log('Failed to setup IntersectionObserver', e);
            reportMessage('Failed to setup IntersectionObserver', {
              err: String(e)
            });
            this.onConfirmVisible();
          }
        } else {
          reportMessage('Missing IntersectionObserver constructor');
          this.onConfirmVisible();
        }
      } else {
        this.onConfirmVisible();
      }
    };
    this.injectIframe = placeholderEl => {
      placeholderEl.appendChild(this.el);
      const placeholderImgEl = placeholderEl.querySelector('img[data-hsv-id]');
      if (placeholderImgEl && placeholderImgEl.src) {
        this.el.style.backgroundImage = `url(${placeholderImgEl.src})`;
        this.el.style.backgroundSize = 'contain';
        this.el.style.backgroundRepeat = 'no-repeat';
        this.el.style.backgroundPosition = 'center';
      }
      this.hidePlaceholderImage();
    };
    this.hidePlaceholderImage = () => {
      const placeholderImgEl = this.placeholderEl.querySelector('img[data-hsv-id]');
      if (placeholderImgEl) {
        placeholderImgEl.style.setProperty('display', 'none', 'important');
      }
    };
    this.on = (eventType, listener) => {
      if (this.eventTarget) {
        this.eventTarget.addEventListener(eventType, listener);
      } else {
        console.log('Browser does not support EventTarget API, on is a no-op.');
      }
    };
    this.trigger = event => {
      if (this.eventTarget) {
        this.eventTarget.dispatchEvent(event);
      }
    };
    this.onConfirmVisible = () => {
      const src = this.iframeEl.getAttribute('data-hsv-src');
      if (src) {
        const [url] = src.split('#');
        const urlParts = url.split('?');
        let baseUrl = urlParts[0];
        const queryString = urlParts[1] || '';
        const params = parseQueryParams(queryString);
        const pageParams = parseQueryParams();
        params.parentOrigin = window.location.origin;
        params.renderContext = this.options.renderContext;

        // used for ATs covering cta/form overlays
        if (pageParams._hsVideoForm && !params.ca) {
          params.ca = serializeConversionAsset({
            type: ConversionAssetTypes.FORM,
            position: ConversionAssetPositions.PRE,
            id: pageParams._hsVideoForm
          });
        } else if (pageParams._hsVideoCta && !params.ca) {
          params.ca = serializeConversionAsset({
            type: ConversionAssetTypes.CTA,
            position: ConversionAssetPositions.PRE,
            id: pageParams._hsVideoCta
          });
        }
        if (isLocal()) {
          baseUrl = baseUrl.replace('play', 'local');
        }
        this.iframeEl.src = `${baseUrl}?${stringifyQueryParams(params)}#hsvid=${this.id}`;
        this.iframeEl.removeAttribute('data-hsv-src');
      } else {
        // cannot check `src` attr directly as it will match the parent window location when pass empty string
        const iframeSrc = this.iframeEl.getAttribute('src');
        if (!iframeSrc || !/http(s?):/.test(iframeSrc)) {
          this.hidePlaceholderImage();
          this.iframeEl.src = this.buildIframeUrl();
          this.el.style.backgroundImage = 'none';
        }
      }
      this.status = PlayerStatus.LOADING;
      this.postMessageToPlayer(MessageTypes.CONFIRM_VISIBLE);
      this.confirmedVisible = true;
    };
    this.log = (...args) => {
      debugLog(`(${this.id})`, ...args);
    };
    this.id = id;
    this.portalId = portalId;
    this.options = _options;
    this.outgoingMessages = [];
    this.receivedMessages = [];
    this.timings = {
      constructedAt: getPerformanceNow()
    };
    this.placeholderEl = _placeholderEl || getEmbedPlaceholderEl(this.id, true);
    this.el = this.createPlayerIframe();
    this.iframeEl = this.el.querySelector('iframe');
    if (window.EventTarget) {
      try {
        this.eventTarget = new EventTarget();
      } catch (e) {
        debugLog('Cannot construct EventTarget, cannot support event handler API.', e);
      }
    }
  }
  createPlayerIframe() {
    const {
      id,
      options,
      placeholderEl
    } = this;
    if (placeholderEl && placeholderEl.querySelector(SERVER_RENDERED_IFRAME_SELECTOR) && placeholderEl.querySelector('.hs-video-wrapper')) {
      debugLog(`Found server-rendered iframe for player ${id}`, options, placeholderEl);
      return placeholderEl.querySelector('.hs-video-wrapper');
    }
    debugLog(`Rendering iframe for player ${id}`, options, placeholderEl);
    const isFullWidthHeight = options.height === '100%' && (options.width === '100%' || options.full_width);
    let containerStyle = '';
    let responsiveWrapperStyle = '';
    let extraInlineStyle = options.style || '';
    let iframeMaxHeight = '';
    if (isFullWidthHeight) {
      responsiveWrapperStyle = containerStyle = `position: absolute; width: 100%; height: 100%`;
    } else {
      const width = typeof options.width === 'string' ? Number(options.width) : options.width;
      let height = typeof options.height === 'string' ? Number(options.height) : options.height;
      const aspectRatioPercent = width && height ? height / width * 100 : DEFAULT_ASPECT_RATIO * 100;

      // downsize player to maxHeight if video is taller, keeping default aspect ratio to let player center the poster/video
      if (typeof options.maxHeight === 'number') {
        height = options.maxHeight;
        iframeMaxHeight = `max-height: ${options.maxHeight}px`;
      }
      let widthStyle = width ? `max-width: ${width}px; ` : '';
      const heightStyle = height && options.maxHeight && height >= options.maxHeight ? `max-height: ${options.maxHeight}px; ` : '';
      if (typeof options.full_width === 'boolean') {
        if (!extraInlineStyle) {
          // mirror historical VY player's default center alignment, but only if `full_width` param is present as Rich text and the Video field would pass
          // allows us to back out of the default in the future as we support external embeds
          extraInlineStyle = 'margin: 0 auto;';
        }
        if (options.full_width) {
          widthStyle = '';
        }
      }
      containerStyle = `${widthStyle} ${heightStyle} ${extraInlineStyle}`;
      responsiveWrapperStyle = `position: relative; height: 0; padding-bottom: ${aspectRatioPercent}%;`;
    }
    const tempWrapperEl = document.createElement('div');
    tempWrapperEl.innerHTML = `
    <div class="hs-video-container" style="${containerStyle.trim()}">
      <div class="hs-video-wrapper" style="${responsiveWrapperStyle.trim()}">
        <iframe id="hs_player_${id}"
          loading="lazy"
          referrerPolicy="origin" 
          sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
          allow="autoplay; fullscreen;"
          style="position: absolute !important; width: 100% !important; height: 100% !important; left: 0; top: 0; border: 0 none; pointer-events: initial; ${iframeMaxHeight}">
        </iframe>
      </div>
    </div>
  `;
    return tempWrapperEl.firstElementChild;
  }
  getPlaceholderSelector() {
    return `[data-hsv-embed-id="${this.id}"]`;
  }
  findPlaceholderEls() {
    return query(this.getPlaceholderSelector());
  }
  findOtherPlaceholderEls() {
    return this.findPlaceholderEls().filter(el => el !== this.placeholderEl && isValidPlaceholder(el));
  }
  selectIframeElFromPage() {
    const iframeEls = query(`iframe#hs_player_${this.id}`);
    if (iframeEls.length > 1) {
      debugLog('Found multiple iframes for player', iframeEls);
      reportMessage('Found multiple iframes for player');
    }
    return iframeEls[0];
  }
  confirmRendered() {
    if (document.contains(this.el)) {
      return;
    }
    if (this.selectIframeElFromPage()) {
      reportMessage('confirmRendered found new iframe el on page via selector.');
      this.replaceIframeFromPage();
      return;
    }
    const otherPlaceholderEls = this.findOtherPlaceholderEls();
    if (otherPlaceholderEls.length) {
      reportMessage(`confirmRendered found duplicate placeholders for player`, {
        embedId: this.id,
        otherPlaceholderCount: otherPlaceholderEls.length
      });
    } else {
      reportMessage(`confirmRendered could not find player el and exhausted options to render!`);
    }
  }
  buildIframeUrl() {
    const iframeParams = buildIframeQueryParams(this.options);
    return getEmbedIframeUrl(this.portalId, this.options.id, iframeParams, this.id, this.options, this.preloaderIframeLoaded);
  }
  isIframeLoaded() {
    return this.iframeLoaded;
  }
  getReadyQueue() {
    return this.outgoingMessages;
  }
  onMessageReceived(type, payload) {
    debugLog(`Received message from player ${this.id}`, type, payload);
    this.receivedMessages.push([type, payload]);
    switch (type) {
      case MessageTypes.VIDEO_LOADED:
        this.video = payload;
        this.handleVideoLoaded(this.video);
        this.trigger(new Event('videoLoaded'));
        break;
      case MessageTypes.PLAYER_LOADED:
        this.executeReadyQueue();
        this.placeholderEl.setAttribute('data-hsv-status', 'loaded');
        this.timings.loadedAfterMs = getPerformanceNow();
        this.trigger(new Event('loaded'));
        break;
      case MessageTypes.PLAYER_READY:
        this.status = PlayerStatus.READY;
        this.placeholderEl.setAttribute('data-hsv-status', 'ready');
        this.timings.readyAfterMs = getPerformanceNow();
        this.trigger(new Event('ready'));
        break;
      case MessageTypes.PLAYER_PLAY:
        this.status = PlayerStatus.PLAYING;
        this.placeholderEl.setAttribute('data-hsv-status', 'playing');
        this.timings.playedAfterMs = getPerformanceNow();
        this.trigger(new Event('play'));
        break;
      case MessageTypes.PLAYER_PROGRESS:
        {
          if (payload && typeof payload === 'object' && 'currentTime' in payload) {
            const currentTime = payload.currentTime;
            const progressEvent = new CustomEvent('progress', {
              detail: {
                currentTime
              }
            });
            this.trigger(progressEvent);
          }
          break;
        }
      case MessageTypes.PLAYER_SECONDS_VIEWED:
        {
          if (payload && typeof payload === 'object' && typeof payload.secondsViewed === 'number') {
            this.timings.secondsViewed = payload.secondsViewed;
          }
          break;
        }
      case MessageTypes.PLAYER_PAUSE:
        this.status = PlayerStatus.PAUSED;
        this.placeholderEl.setAttribute('data-hsv-status', 'paused');
        this.trigger(new Event('pause'));
        break;
      case MessageTypes.PLAYER_ENDED:
        this.status = PlayerStatus.ENDED;
        this.placeholderEl.setAttribute('data-hsv-status', 'ended');
        this.trigger(new Event('ended'));
        break;
      case MessageTypes.PLAYER_SHOW_OVERLAY:
        {
          const conversionAsset = payload || this.options.conversionAsset;
          if (!(conversionAsset && conversionAsset.type && conversionAsset.id)) {
            reportMessage('Unexpected PLAYER_SHOW_OVERLAY message');
            break;
          }
          this.status = PlayerStatus.CONVERSION_ASSET_OVERLAY;
          this.placeholderEl.setAttribute('data-hsv-status', `${conversionAsset.type.toLowerCase()}-overlay`);
          if (conversionAsset.type === ConversionAssetTypes.CTA) {
            try {
              renderCta(this, conversionAsset);
            } catch (e) {
              reportMessage('Error thrown rendering CTA');
            }
          } else if (conversionAsset.type === ConversionAssetTypes.FORM) {
            try {
              renderForm(this, conversionAsset);
            } catch (e) {
              reportMessage('Error thrown rendering form');
            }
          }
          break;
        }
      case MessageTypes.PLAYER_ORPHANED:
        reportMessage('Player did not receive SET_PAGE_META postMessage', {
          iframeUrl: this.iframeEl.src
        });
        break;
      case MessageTypes.PLAYER_ERROR:
        reportMessage('In-app player reported error', {
          iframeUrl: this.iframeEl.src
        });
        this.trigger(new CustomEvent('error', {
          detail: payload
        }));
        break;
      default:
        break;
    }
  }
  handleVideoLoaded(video) {
    if (!this.iframeEl.title) {
      this.iframeEl.title = video.altText || video.title;
    }
  }
  triggerPause() {
    this.postMessageToPlayer(MessageTypes.SET_PLAYER_STATUS, {
      status: PlayerStatus.PAUSED
    });
  }
  postMessageToPlayer(type, payload = null, queueIfNotReady = true) {
    if (this.iframeLoaded) {
      if (!this.iframeEl.contentWindow) {
        this.replaceIframeFromPage();
      }
      if (this.iframeEl.contentWindow) {
        this.sendMessage(type, payload);
        return;
      } else {
        debugLog(`Could not access contentWindow for player ${this.id}`, type, payload);
      }
    }
    if (queueIfNotReady) {
      debugLog(`Queuing postMessage for player ${this.id}`, type, payload);
      this.outgoingMessages.push([type, payload]);
    }
  }
  sendMessage(type, payload) {
    if (!this.iframeEl.contentWindow) {
      this.replaceIframeFromPage();
    }
    if (!this.iframeEl || !this.iframeEl.contentWindow) {
      debugLog(`sendMessage could not access contentWindow for player ${this.id}`, type, payload);
      return;
    }
    debugLog(`Sending postMessage to player ${this.id}`, type, payload);
    if (payload && typeof payload === 'object') {
      if (type === MessageTypes.SET_UTK && 'utk' in payload) {
        this.utk = payload.utk;
      }
    }
    try {
      this.iframeEl.contentWindow.postMessage({
        type,
        payload
      }, getEmbedIframeDomain(this.options));
    } catch (e) {
      this.log('Failed to postMessage player', e);
    }
  }
  replaceIframeFromPage() {
    const iframeElFromPage = this.selectIframeElFromPage();
    if (iframeElFromPage) {
      debugLog('Replacing iframeEl from page with ', this.iframeEl, this.iframeEl === iframeElFromPage);
      this.iframeEl = iframeElFromPage;
    }
  }
  executeReadyQueue() {
    if (this.outgoingMessages.length) {
      if (this.iframeLoaded) {
        debugLog(`Player ${this.id} reloaded, executing readyQueue of length ${this.outgoingMessages.length}`);
        if (this.utk) {
          const {
            utk
          } = this;
          this.sendMessage(MessageTypes.SET_UTK, {
            utk
          });
        }
      } else {
        debugLog(`Executing readyQueue of length ${this.outgoingMessages.length} for player ${this.id}`);
      }
      this.outgoingMessages.forEach(([messageType, payload]) => {
        this.sendMessage(messageType, payload);
      });
    }
    this.iframeLoaded = true;
  }
  setupIntersectionObserver() {
    if (isVisible(this.el)) {
      this.initiallyVisible = true;
      this.onConfirmVisible();
      return;
    }
    const intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.log('Found player in viewport via IntersectionObserver');
          this.onConfirmVisible();
          intersectionObserver.disconnect();
        }
      });
    }, {
      rootMargin: '100px',
      // iframe load will trigger when videos are within 100px of the viewport
      threshold: 0
    });
    intersectionObserver.observe(this.el);
  }
}