import { PlayerStatus } from '../types';
const INITIAL_STATUSES = [PlayerStatus.INITIAL, PlayerStatus.LOADING, PlayerStatus.READY];
const VIDEO_EMBED_JS_TYPE = 'video-embed-js'; // iframe created and injected on DOMContentLoaded by video-embed JS
const SERVER_RENDERED_EMBED_TYPE = 'hubl-iframe'; // iframe rendered server-side (`Video:EagerIframeRendering` gate)

export const getPerformanceNow = () => {
  return window.performance.now ? window.performance.now() : null;
};
const getMinimumTimestamp = (players, fieldName) => {
  const timestamps = players.filter(player => typeof player.timings[fieldName] === 'number').map(player => player.timings[fieldName]);
  return timestamps.length ? Math.round(Math.min(...timestamps)) : null;
};

// meant to integrate with https://git.hubteam.com/HubSpot/content-cwv-embed
export const getPerformanceMetrics = players => {
  let embedType = VIDEO_EMBED_JS_TYPE;
  if (players.some(p => p.options.renderContext && p.options.renderContext.startsWith('hubl-iframe'))) {
    embedType = SERVER_RENDERED_EMBED_TYPE;
  }
  const firstVideoLoadedAt = getMinimumTimestamp(players, 'loadedAfterMs');
  const firstVideoReadyAt = getMinimumTimestamp(players, 'readyAfterMs');
  const firstVideoPlayedAt = getMinimumTimestamp(players, 'playedAfterMs');
  return {
    embedType,
    embedVersion: 1,
    firstVideoLoadedAt,
    // react has mounted video-player-ui app within iframe and fetched video CRM object
    firstVideoReadyAt,
    // video has loaded metadata and is ready to play
    firstVideoPlayedAt,
    numHsVideos: players.length,
    numAutoplay: players.filter(p => p.options.autoplay).length,
    numInitiallyVisible: players.filter(p => p.initiallyVisible).length,
    numInteractedWith: players.filter(p => !INITIAL_STATUSES.includes(p.status)).length,
    secondsOfVideoPlayed: players.reduce((total, p) => {
      return total + (p.timings.secondsViewed || 0);
    }, 0)
  };
};