import { MessageTypes } from '../constants';
import { ConversionAssetPositions } from '../types';
import { getHublet } from './cms';
import { debugLog } from './debug';
import { getColorValue, isTransparent, loadScript, setStyles } from './dom';
import { reportMessage } from './errors';
import { isAuthedAppPreview, isQa } from './env';

// designed to match UIDialogCloseButton styles. avoids <a> tag as it tends to be heavily styled / text oriented on sites
export const CLOSE_BUTTON_HTML = `
  <div class="hsv-overlay-close" aria-label="Close" role="button" data-action="close" 
       style="position: absolute; display: block; top: 15px; right: 15px; width: 16px; height: 16px; background: transparent; cursor: pointer">
    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" style="position: absolute; top: 0; stroke: white; stroke-width: 2px">
      <path d="M14.5,1.5l-13,13m0-13,13,13" transform="translate(-1 -1)"></path>
    </svg>
    <span style="position: absolute; padding: 20px; border-radius: 100%; top: -12px; left: -12px; background: rgba(255, 255, 255, 0.1)" />
    <style>
      .hsv-overlay-close > span { display: none; }
      .hsv-overlay-close:hover > span { display: block; }
    </style>
  </div>
`;
export const isWebInteractiveId = id => /^\d+$/.test(id);
function buildCtaWrapperHtml(portalId, asset) {
  if (isWebInteractiveId(asset.id)) {
    return `
    <div class="hsv-cta-wrapper hsv-overlay" style="position: absolute; display: flex; width: 100%; height: 100%;
                                        align-items: center; justify-content: center;">
      <div class="hs-cta-embed hs-cta-simple-placeholder hs-cta-embed-${asset.id}">
        <a target="_blank" rel="noopener noreferrer">
          <img
            alt="Video CTA"
            src="${getCtaFallbackImageUrl(asset.id, portalId)}"
          />
        </a>
      </div>
      ${asset.preventSkip ? '' : CLOSE_BUTTON_HTML}
    </div>
    `;
  }
  return `
    <div class="hsv-cta-wrapper hsv-overlay" style="position: absolute; display: flex; width: 100%; height: 100%;
                                        align-items: center; justify-content: center;">
        <span class="hs-cta-node hs-cta-${asset.id}" id="hs-cta-${asset.id}">
          <a target="_blank" rel="noopener noreferrer">
            <img
              class="hs-cta-img"
              id="hs-cta-img-${asset.id}"
              alt="Video CTA"
              src="${getCtaFallbackImageUrl(asset.id, portalId)}"
            />
          </a>
        </span>
        ${asset.preventSkip ? '' : CLOSE_BUTTON_HTML}
      </div>
    `;
}
function getCtaFallbackImageUrl(guid, portalId) {
  const domain = isQa() ? 'hubspotqa.com' : 'hubspot.com';
  let fallbackImgOrigin = `//no-cache.${domain}`;
  if (getHublet() === 'eu1') {
    fallbackImgOrigin = isQa() ? '//hubspot-no-cache-eu1-qa.s3.amazonaws.com' : '//hubspot-no-cache-eu1-prod.s3.amazonaws.com';
  }
  const filename = isWebInteractiveId(guid) ? `interactive-${guid}` : guid;
  return `${fallbackImgOrigin}/cta/default/${portalId}/${filename}.png`;
}
export function renderCta(player, asset) {
  const {
    id
  } = asset;
  const ctaWrapper = document.createElement('div');
  ctaWrapper.innerHTML = buildCtaWrapperHtml(player.portalId, asset);
  const wrapperEl = ctaWrapper.firstElementChild;
  player.getWrapperEl().append(wrapperEl);
  const handleSkip = () => {
    player.postMessageToPlayer(MessageTypes.CTA_SKIPPED);
    wrapperEl.style.display = 'none';
  };
  const handleClick = () => {
    player.postMessageToPlayer(MessageTypes.CTA_CLICKED);
    wrapperEl.style.display = 'none';
  };
  const closeEl = wrapperEl.querySelector('.hsv-overlay-close');
  if (closeEl) {
    closeEl.addEventListener('click', handleSkip);
  }
  const onScriptLoaded = () => {
    if (!window.hbspt || !window.hbspt.cta) {
      reportMessage('Could not load CTA JS');
      handleSkip();
      return;
    }
    // copied from renderer, maybe important?
    window.hbspt.cta._relativeUrls = true;
    // ensure clicks are tracked with entire page url including query + hash, which we may use to identify which video displayed CTA
    window.hbspt.cta.canonicalURL = window.location.href;
    const ctaOptions = {
      region: getHublet() || 'na1',
      useNewLoader: true,
      enforceTargetIsBlank: true
    };
    if (isQa()) {
      ctaOptions.env = 'qa';
    }
    const anchorEl = player.el.querySelector('.hs-cta-node');
    anchorEl.addEventListener('click', handleClick);
    window.hbspt.cta.load(player.portalId, id, ctaOptions);
    // needed for CTA to render post DOMReady
    window.hbspt.cta.loadQueue();
  };
  const onWebInteractiveScriptLoaded = () => {
    if (!window.__PRIVATE__HubspotCtaClient || typeof window.__PRIVATE__HubspotCtaClient.loadCta !== 'function') {
      reportMessage('Web interactive API not found after load');
      handleSkip();
      return;
    }
    const elementToEmbed = wrapperEl.querySelector('.hs-cta-simple-placeholder');
    window.hsCtasOnReady = window.hsCtasOnReady || [];
    window.hsCtasOnReady.push(() => {
      window.__PRIVATE__HubspotCtaClient.loadCta({
        contentId: Number(asset.id),
        options: {
          openInNewTab: true,
          elementToEmbed,
          onNavigate: handleClick
        }
      });
    });
  };
  if (isWebInteractiveId(asset.id)) {
    if (window.__PRIVATE__HubspotCtaClient) {
      onWebInteractiveScriptLoaded();
    } else {
      let webInteractivesEmbedUrl = `${window.location.origin}/hs/cta/web-interactives-embed.js`;
      if (isAuthedAppPreview()) {
        webInteractivesEmbedUrl = isQa() ? 'https://js.hubspotqa.com/web-interactives-embed.js' : 'https://js.hscta.net/web-interactives-embed.js';
      }
      loadScript(webInteractivesEmbedUrl).then(onWebInteractiveScriptLoaded).catch(err => {
        reportMessage('Failed to load web interactive JS', {
          errorMessage: String(err)
        });
        handleSkip();
      });
    }
    return;
  }
  if (window.hbspt && window.hbspt.cta) {
    onScriptLoaded();
  } else {
    let ctaEmbedUrl = `${window.location.origin}/hs/cta/cta/current.js`;
    if (isAuthedAppPreview()) {
      ctaEmbedUrl = isQa() ? 'https://js.hubspotqa.com/cta/current.js' : 'https://js.hscta.net/cta/current.js';
    }
    loadScript(ctaEmbedUrl).then(onScriptLoaded).catch(err => {
      console.error('Failed to display CTA', err);
      reportMessage('Failed to display CTA', {
        errorMessage: String(err)
      });
      handleSkip();
    });
  }
}
let originalScrollTo;
function preventScrollTo() {
  // forms embed scrolling too far / randomly & causing jarring effects https://git.hubteam.com/HubSpot/forms-embed-js/blob/ae06b520e132ef923aa59104d55a470ec86177d3/ui-forms-embed-v3/static/js/form/PageActions.js#L74
  if (originalScrollTo) {
    return;
  }
  try {
    originalScrollTo = window.scrollTo;
    window.scrollTo = (...args) => {
      debugLog('Prevented scrollTo with args:', ...args);
    };
  } catch (e) {
    debugLog('Failed to patch window.scrollTo to prevent forms auto-scroll', e);
  }
}
function restoreScrollTo() {
  if (!originalScrollTo) {
    return;
  }
  try {
    window.scrollTo = originalScrollTo;
  } catch (e) {
    debugLog('Failed to un-patch window.scrollTo to restore auto-scroll ability', e);
  }
  originalScrollTo = undefined;
}
export const MODAL_FORM_WRAPPER_CLASSNAME = 'hsv-form-wrapper';
export const SUBMIT_BUTTON_SELECTOR = '.hs-submit .hs-button';
export const LIGHT_COLOR_VALUE_THRESHOLD = 155;
export const LEGACY_VIDEO_FORM_TYPE_ID = 9;
export const DEFAULT_INLINE_MESSAGE = 'Thanks for your response!';
function buildFormWrapperHtml(asset) {
  return `
      <div class="hsv-overlay hsv-form-modal" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">
        <div class="${MODAL_FORM_WRAPPER_CLASSNAME}" style="height: 100%; overflow-y: auto; padding: 20px 40px; text-align: left">
          <div class="hsv-form" />
        </div>
        ${asset.preventSkip ? '' : CLOSE_BUTTON_HTML}
      </div>
  `;
}
const overrideFormStyles = (wrapperEl, playButtonColor) => {
  const formEl = wrapperEl.querySelector('form');
  if (!formEl) {
    debugLog('Form el not found in onFormReady callback');
    return;
  }
  const actionsEl = formEl.querySelector('div.actions');
  if (actionsEl) {
    setStyles(actionsEl, {
      margin: 0,
      padding: 0
    });
  }
  if (!playButtonColor || playButtonColor === '#ffffff') {
    return;
  }
  if (playButtonColor[0] !== '#') {
    playButtonColor = `#${playButtonColor}`;
  }
  const colorValue = getColorValue(playButtonColor);
  const isLightColor = colorValue > LIGHT_COLOR_VALUE_THRESHOLD;
  try {
    const submitButtonEl = wrapperEl.querySelector(`form ${SUBMIT_BUTTON_SELECTOR}`);
    if (!submitButtonEl) {
      debugLog('Could not find form submit button to apply color to');
      return;
    }
    setStyles(submitButtonEl, {
      backgroundColor: playButtonColor,
      borderColor: playButtonColor
    });
    if (isLightColor) {
      setStyles(submitButtonEl, {
        color: 'black'
      });
    }
  } catch (e) {
    console.error('Failed to update form submit button color', e);
  }
};
export function renderForm(player, asset) {
  let thankYouMessageVisible = false;
  let isLegacyForm = false;
  let isThankYouMessageEmpty = false;
  let formDefinition;
  const {
    id
  } = asset;
  const tempWrapperEl = document.createElement('div');
  tempWrapperEl.innerHTML = buildFormWrapperHtml(asset);
  const wrapperEl = tempWrapperEl.firstElementChild;
  player.getWrapperEl().append(wrapperEl);
  const formTargetEl = wrapperEl.querySelector('.hsv-form');
  const handleSkip = () => {
    restoreScrollTo();
    player.postMessageToPlayer(thankYouMessageVisible ? MessageTypes.FORM_SUBMITTED : MessageTypes.FORM_SKIPPED, {
      isLegacyForm
    });
    wrapperEl.style.display = 'none';
  };
  const handleWatchVideoClicked = () => {
    player.postMessageToPlayer(MessageTypes.FORM_SUBMITTED, {
      isLegacyForm
    });
    wrapperEl.style.display = 'none';
  };
  const handleSubmit = () => {
    restoreScrollTo();
    if (isThankYouMessageEmpty) {
      handleWatchVideoClicked();
    }
    thankYouMessageVisible = true;
    const inlineMessageEl = wrapperEl.querySelector('.submitted-message') || formTargetEl;
    if (!wrapperEl.querySelector('.hsv-overlay-close')) {
      const tempEl = document.createElement('div');
      tempEl.innerHTML = CLOSE_BUTTON_HTML;
      tempEl.querySelector('.hsv-overlay-close').addEventListener('click', () => handleWatchVideoClicked());
      wrapperEl.appendChild(tempEl.firstElementChild);
    }
    const watchVideoHtml = `
      <div style="margin-top: 10px; text-align: center">
        <a data-test-id="form-watch-video" style="cursor: pointer">Watch video</a>
      </div>
    `;
    const watchVideoEl = document.createElement('div');
    watchVideoEl.innerHTML = watchVideoHtml;
    watchVideoEl.querySelector('a').addEventListener('click', () => handleWatchVideoClicked());

    // give inline message time to render, see https://issues.hubspotcentral.com/browse/CG-47437?filter=-3
    setTimeout(() => {
      inlineMessageEl.append(watchVideoEl.firstElementChild);
    }, 250);
  };
  const closeEl = player.el.querySelector('.hsv-overlay-close');
  if (closeEl) {
    closeEl.addEventListener('click', handleSkip);
  }
  const initForm = () => {
    if (!window.HubSpotForms) {
      reportMessage('Could not load Forms JS');
      handleSkip();
      return;
    }
    preventScrollTo();
    player.form = window.HubSpotForms.create({
      formId: id,
      portalId: player.portalId,
      env: isQa() ? 'qa' : null,
      region: getHublet(),
      target: `${player.getPlaceholderSelector()} .hsv-form`,
      css: '',
      // prevents injecting inline styles, see VEP-1019
      onFormDefinitionFetchSuccess: options => {
        formDefinition = options.form;
        if (!formDefinition) {
          return;
        }
        if (formDefinition.formTypeNumber === LEGACY_VIDEO_FORM_TYPE_ID) {
          isLegacyForm = true;
        }
        if (!formDefinition.inlineMessage) {
          isThankYouMessageEmpty = true;
        }
      },
      onFormReady: () => {
        const formEl = wrapperEl.querySelector('form');
        if (formEl && isTransparent(formEl)) {
          setStyles(formTargetEl, {
            background: 'white',
            padding: '10px 20px'
          });
        }
        overrideFormStyles(wrapperEl, player.options.playButtonColor);

        // forms configured to redirect instead of showing an inline message prevent viewing video after,
        // so re-create the form with a default inlineMessage instead
        if (formDefinition.redirectUrl && asset.position !== ConversionAssetPositions.POST) {
          debugLog(`Overriding redirectUrl ${formDefinition.redirectUrl} with generic inlineMessage`);
          player.form.setContext({
            inlineMessage: DEFAULT_INLINE_MESSAGE,
            redirectUrl: ''
          });
        }
      },
      getExtraMetaDataBeforeSubmit: () => {
        // potentially useful for displaying which video a form was submitted for on timeline, in submissions tool in future
        return {
          hs_video_id: player.options.id,
          hs_video_title: player.video ? player.video.title : null
        };
      },
      // to ensure forms-embed renders the inline message before we insert our "watch video" link below
      onFormSubmitted: () => setTimeout(handleSubmit, 0)
    });
  };
  if (window.HubSpotForms) {
    initForm();
  } else {
    const formsJsDomain = isQa() ? 'hsformsqa.net' : 'hsforms.net';
    loadScript(`//js.${formsJsDomain}/forms/embed/v2.js`).then(initForm).catch(err => {
      console.error('Failed to display form', err);
      reportMessage('Failed to display form', {
        errorMessage: String(err)
      });
      handleSkip();
    });
  }
}
// in-app form previews are rendered inside the player iframe
export function renderPlayerPreviewForm(wrapperEl, asset, video, options, {
  onSkip,
  onSubmit
}) {
  const onScriptLoaded = () => {
    if (!window.HubSpotForms) {
      onSkip();
      throw new Error('Could not load Forms JS');
    }
    return window.HubSpotForms.create({
      formId: asset.id,
      portalId: video.portalId,
      env: isQa() ? 'qa' : null,
      region: getHublet(),
      target: `.${MODAL_FORM_WRAPPER_CLASSNAME}`,
      css: '',
      onFormReady: () => {
        overrideFormStyles(wrapperEl, options.playButtonColor);
      },
      onFormSubmitted: () => setTimeout(onSubmit)
    });
  };
  if (window.HubSpotForms) {
    return onScriptLoaded();
  } else {
    const formsJsDomain = isQa() ? 'hsformsqa.net' : 'hsforms.net';
    return loadScript(`//js.${formsJsDomain}/forms/embed/v2.js`).then(onScriptLoaded);
  }
}